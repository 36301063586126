import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./image-carousel.css";

export default function ImageCarousel() {
  return (
    <div className="carousel-container w-full max-w-[1218px] mx-auto">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper: any) => {}}
        onSlideChange={() => {}}
      >
        <SwiperSlide>
          <img
            className="rounded-3xl w-full max-w-[1060px] mx-auto"
            src="/images/home-header-bg.jpg"
            alt="placeholder"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="rounded-3xl w-full max-w-[1060px] mx-auto"
            src="/images/home-header-bg.jpg"
            alt="placeholder"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="rounded-3xl w-full max-w-[1060px] mx-auto"
            src="/images/home-header-bg.jpg"
            alt="placeholder"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="rounded-3xl w-full max-w-[1060px] mx-auto"
            src="/images/home-header-bg.jpg"
            alt="placeholder"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

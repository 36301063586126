import React, { ReactNode } from "react";

interface SectionProps {
  children: ReactNode;
  className?: string;
  dataNavbarColor?: "light" | "dark";
}

const Section = ({
  children,
  className,
  dataNavbarColor = "light",
}: SectionProps) => {
  return (
    <section
      className={`section px-6 ${className ? className : ""}`}
      data-navbar-color={dataNavbarColor}
    >
      {children}
    </section>
  );
};

export default Section;

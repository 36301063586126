import React from "react";
import { Heading, Paragraph } from "../../components";
import { Header, Container } from "../../components/layout";
import Listings from "../../components/page-sections/Apartments/Listings";


const Home = () => {
  return (
    <>
      <Header
        className="header bg-[#8DB3D9] h-[538px] flex items-center relative"
        dataNavbarColor="light"
      >
        <Container variant="lg" className="shrink-0 w-full">
          <Heading variant="lg" className="text-white max-w-[450px] mt-10 relative z-[5]">
            Discover Comfort in Every Corner: Book Your Stay Today!
          </Heading>

          <img
            src="/images/footer-img.png"
            className="absolute right-0 bottom-0"
            alt="house"
          />
        </Container>
      </Header>

      <Listings />
    </>
  );
};

export default Home;

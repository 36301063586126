import React from "react";
import Container from "../../layout/Container";
import Heading from "../../Heading";

import { PiMapPinArea, PiDotBold } from "react-icons/pi";
import Button from "../../Button";
import Select from "../../Select";
import { Section } from "../../layout";

const FilterCategory = ({ filterTitle, children }: any) => {
  return (
    <div className="border border-[#EBEBEB] px-8 py-7 rounded-lg h-fit text-sm">
      <p className="font-semibold mb-6 text-base">{filterTitle}</p>
      {children}
    </div>
  );
};

const ListingCard = () => {
  return (
    <div className="rounded border border-[#EBEBEB]">
      <img src="/images/listing-placeholder.jpg" alt="placeholder" />
      <div className="p-4">
        <Heading className="inline">$398</Heading>
        <p className="inline font-medium mt-[-4px]">/per night</p>
        <div className="flex items-center gap-2 mt-4">
          <PiMapPinArea size={24} />
          <p>Sandton, South Africa</p>
        </div>
        <div className="flex items-center mt-4">
          4 Bedrooms <PiDotBold size={40} />6 Bathrooms
        </div>
      </div>
    </div>
  );
};

const Listings = () => {
  return (
    <Section className="py-10" dataNavbarColor="dark">
      <Container variant="lg" className="lg:flex lg:gap-[75px]">
        <div className="lg:w-[360px] flex flex-col gap-6">
          <FilterCategory filterTitle="Sort By">
            <div className="grid gap-4">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                Lagos
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                South Africa
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                London
              </div>
            </div>
          </FilterCategory>
          <FilterCategory filterTitle="Price Range">
            <div className="grid gap-4">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                &lt; ₦70,000
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                ₦80,000 - ₦100,000
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                ₦120,000 - ₦150,000
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded checked:bg-[#0361CC]"
                />
                ₦150,000+
              </div>
            </div>
          </FilterCategory>
          <FilterCategory filterTitle="Refine Results">
            <div className="grid gap-4">
              <Select placeholder="Bedrooms" />
              <Select placeholder="Bathrooms" />
              <Select placeholder="Pools" />
            </div>
            <Button className="mt-6" variant="sm">
              Clear
            </Button>
          </FilterCategory>
        </div>
        <div className="grid lg:grid-cols-2 gap-6">
          <ListingCard />
          <ListingCard />
          <ListingCard />
          <ListingCard />
          <ListingCard />
          <ListingCard />
        </div>
      </Container>
    </Section>
  );
};

export default Listings;

import React from "react";
import Heading from "../../Heading";
import { Section } from "../../layout";

const ReviewCard = ({ name, handle, review, avatar }: any) => {
  return (
    <div className="bg-[#FAFAFB] p-8 rounded-2xl w-[416px] text-[#05091F] shrink-0 flex flex-col justify-between min-h-[314px]">
      <p className="text-[17px] leading-7">{review}</p>
      <div className="flex gap-4">
        <img
          className="rounded-full h-12 w-12 object-cover"
          src={avatar}
          alt="avatar"
        />
        <div className="flex flex-col justify-between">
          <p className="font-medium">{name}</p>
          <p className="text-[#697586] mt-auto">{handle}</p>
        </div>
      </div>
    </div>
  );
};

const WhatTheySay = () => {
  const reviewArr = [
    {
      name: "Rehk Mansa",
      handle: "@Rehkk_M",
      avatar: "/images/profile-1.jpg",
      review:
        "Staying at a JR Ltd apartment was a fantastic experience! The place was clean, beautifully decorated, and had all the amenities I needed for a comfortable stay. The location was perfect, with easy access to local attractions.",
    },
    {
      name: "Adegbola Wunmi",
      handle: "@Wunmi",
      avatar: "/images/image (2).png",
      review:
        "I had a wonderful stay at JR Ltd! The apartment was spacious and felt like a home away from home. The staff were incredibly helpful and responsive to my needs. I highly recommend them to anyone looking for a rental!",
    },
    {
      name: "Mfon John",
      handle: "@Jon__",
      avatar: "/images/image (1).png",
      review:
        "Jazz Realities exceeded my expectations! The apartment was in a great neighborhood, and I loved the local recommendations they provided. It was perfect for my family vacation, and we felt right at home.",
    },
    {
      name: "Oshoporu Emmanuel",
      handle: "@Oshopos__",
      avatar: "/images/image.png",
      review:
        "Absolutely loved my experience with Jazz Realities! The apartment was immaculate, and the views were stunning. The customer service was outstanding—they really went above and beyond to make sure I had everything I needed. Highly recommended",
    },
  ];
  return (
    <Section className="pr-0 py-20" dataNavbarColor="dark">
      <Heading className="text-center mb-12">What they say about us</Heading>
      <div className={`flex gap-2 overflow-x-auto max-w-[calc(50vw+625px)] ml-auto`}>
        {reviewArr.map((review, i) => (
          <ReviewCard key={i} {...review} />
        ))}
      </div>
    </Section>
  );
};

export default WhatTheySay;

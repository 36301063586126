import React from "react";
import { Heading, Paragraph } from "../../components";
import WhatWeOffer from "../../components/page-sections/common/WhatWeOffer";
import {
  FAQ,
  InsightsSection,
  WhySection,
} from "../../components/page-sections/common";
import WhatTheySay from "../../components/page-sections/common/WhatTheySay";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import { Header } from "../../components/layout";

const FilterSelect = ({ label }: { label: string }) => {
  return (
    <div className="[&:not(:last-child)]:border-r [&:not(:first-child)]:pl-7 pr-5 border-[#BCBCBC]">
      <label className="block text-[#EBEBEB] uppercase font-medium text-[10px] ml-1 mb-1">
        {label}
      </label>
      <select className="bg-transparent text-sm lg:w-[130px]">
        <option value="">Show all</option>
      </select>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <Header
        className="header h-screen max-h-[840px] bg-cover bg-center py-16 flex items-center justify-center"
        style={{ backgroundImage: "url('/images/home-header-bg.jpg')" }}
        dataNavbarColor="light"
      >
        <div className="text-white">
          <h2 className="text-3xl lg:text-[40px] lg:leading-[54px] font-semibold text-center max-w-[950px] mx-auto">
            Unlock the Power of Real Estate Investment with Expert Guidance
          </h2>
          <p className="text-center max-w-[780px] mx-auto mt-4">
            At J.R .LTD, we deliver tailored real estate strategies designed to
            maximize your returns and secure your financial future. From
            investment consulting to facility management, we provide a seamless
            approach to property investment.
          </p>
          <div className="max-w-[944px] mt-8 px-8 py-5 rounded-[40px] bg-[#FFFFFF33] backdrop-blur-[12px] hidden lg:flex items-center justify-between">
            <div className="flex">
              <FilterSelect label="Country" />
              <FilterSelect label="Bedroom" />
              <FilterSelect label="Bathrooms" />
              <FilterSelect label="Price Range" />
            </div>
            <button className="bg-[#0361CC] rounded-[32px] px-6 py-3">
              Search
            </button>
          </div>
        </div>
      </Header>
      <WhySection
        title="Why J.R. LTD?"
        paragraphs={[
          "At J.R. LTD , we specialize in real estate investment portfolio management, helping investors maximize returns while minimizing risks. From strategic acquisitions to comprehensive property management, we tailor our services to meet your financial goals.",
          "With our subsidiary, 10ANTS NG, and its facility management app, we bring innovative solutions to estate management, ensuring seamless operations across all properties.",
        ]}
      />
      <InsightsSection />
      <section className="py-16 px-6 [&_.swiper-button-prev]:left-[32px]">
        <Heading className="text-center mb-2" variant="lg">
          Gallery
        </Heading>
        <Paragraph className="text-center max-w-[870px] mx-auto mb-10">
          At J.R. LTD our portfolio showcases a diverse range of high-value real
          estate investments, from luxury apartments to exclusive residential
          estates. Each project reflects our commitment to strategic investment,
          meticulous management, and long-term value creation. Explore our
          featured properties to see how we maximize returns for our clients
          through innovative real estate solutions.
        </Paragraph>
        <ImageCarousel />
      </section>
      <FAQ />
      <WhatWeOffer />
      <WhatTheySay />
    </>
  );
};

export default Home;

import React from "react";
import Container from "../../layout/Container";
import Heading from "../../Heading";
import { Section } from "../../layout";

interface WhySectionProps {
  title: string;
  paragraphs: string[];
}

const WhySection = ({ title, paragraphs }: WhySectionProps) => {
  return (
    <Section className="px-6 py-20" >
      <Container className="flex items-center justify-center" variant="lg">
        <div>
          <img
            className="h-[300px] mb-28"
            src="/images/card-1.jpg"
            alt="placeholder"
          />
          <img
            className="h-[200px]"
            src="/images/card-3.jpg"
            alt="placeholder"
          />
        </div>
        <div className="text-center max-w-[624px]">
          <Heading
            className="mb-[60px] w-fit mx-auto relative before:content-[''] before:absolute before:h-3 before:w-[120px] before:bg-[#0361CC] before:right-0 before:bottom-[-10px]"
            variant="lg"
          >
            {title}
          </Heading>
          {paragraphs.map((p, i) => (
            <p key={i} className="[&:not(:first-child)]:mt-6">
              {p}
            </p>
          ))}
        </div>
        <div>
          <img
            className="h-[300px] mb-28"
            src="/images/card-2.jpg"
            alt="placeholder"
          />
          <img
            className="h-[200px]"
            src="/images/card-4.jpg"
            alt="placeholder"
          />
        </div>
      </Container>
    </Section>
  );
};

export default WhySection;

import React from "react";

interface InputProps {
  className?: string;
  placeholder?: string;
  value?: string;
  label?: string;
  required?: boolean;
  type?: string;
}

const Input = ({
  type = "text",
  className,
  label,
  placeholder,
  required,
}: InputProps) => {
  return (
    <div className={`${className ? className : ""}`}>
      {label && (
        <label className="block mb-2 font-medium">
          {label} {required && <span className="text-[#FF0000]">&#42;</span>}
        </label>
      )}

      <input
        type={type}
        className="border border-[#EBEBEB] w-full h-12 px-3 rounded-lg placeholder:text-sm focus:outline-none"
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default Input;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "./Container";
import { CgMenuRight } from "react-icons/cg";

const Navbar = () => {
  const { pathname } = useLocation();
  const navbarLinks = [
    { title: "About Us", link: "/about" },
    { title: "10ANTS NG", link: "/10ants" },
    { title: "Services", link: "/#services" },
    { title: "Contact Us", link: "/contact" },
    { title: "Apartments", link: "/apartments" },
  ];

  const [navbarBackground, setNavbarBackground] = useState<string | null>(
    "light"
  );

  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);

  useEffect(() => {
    if (mobileNavbarOpen) {
      setMobileNavbarOpen(false);
    }
    if(pathname == "/"){
      setNavbarBackground('light');
    }else{
      setNavbarBackground('')
    }
  }, [pathname]);

  useEffect(() => {
    const sections = document.querySelectorAll(".section");
    const headers = document.querySelectorAll(".header");

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          const sectionColor = entry.target.getAttribute("data-navbar-color");
          if (sectionColor) {
            setNavbarBackground(sectionColor);
          } else {
            setNavbarBackground("");
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: "-112px 0px 0px 0px",
      // threshold: 0.2,
    });
    if(pathname == "/" || pathname == "/apartments"){
      headers.forEach((header: any) => observer.observe(header));
      sections.forEach((section: any) => observer.observe(section));
    }

    return () => observer.disconnect();
  }, [pathname]);

  return (
    <>
      {/* -------------------------- Mobile navbar -------------------------- */}
      <nav
        className={`fixed bg-white top-0 left-0 duration-300 z-30 w-screen flex lg:hidden px-6 py-3 items-center justify-between`}
      >
        <Link to="/">
          <img src="/svgs/jazz-realties-logo.svg" alt="Jazz Realties Logo" />
        </Link>
        <CgMenuRight
          size={32}
          color="#0361CC"
          onClick={() => setMobileNavbarOpen((prev) => !prev)}
        />
        <ul
          className={`text-black px-6 w-full duration-700 z-[-1] bg-white left-0 absolute pt-8 ${
            mobileNavbarOpen ? "top-20" : "top-[-550px]"
          }`}
        >
          {navbarLinks.map(({ title, link }, i) => (
            <li
              key={i}
              className="[&:not(:last-child)]:border-b [&:not(:last-child)]:border-gray-200"
            >
              <Link
                className={`block py-7 ${
                  pathname === link ? "text-[#0361CC] font-semibold" : ""
                }`}
                to={link}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      {/* -------------------------- Desktop navbar -------------------------- */}
      <nav
        className={`hidden lg:block p-6 fixed w-screen duration-300 z-30 ${
          navbarBackground === "light" ? "bg-transparent" : "bg-white"
        }
      
      `}
      >
        <Container variant="lg" className="flex justify-between items-center">
          <Link to="/">
            <img src="/svgs/jazz-realties-logo.svg" alt="Jazz Realties Logo" />
          </Link>
          <ul
            className={`flex gap-3 ${
              navbarBackground === "light" ? "text-white" : "text-black"
            } [& > a]:px-3`}
          >
            {navbarLinks.map(({ title, link }, i) => {
              if(title == 'Services'){
                return(
                  <li key={i}>
                    <a
                      className={`px-3  ${
                        pathname === link ? "text-[#0361CC] font-semibold" : ""
                      }`}
                      href={link}
                    >
                      {title}
                    </a>
                  </li>
                )

              }else {
                return(
                  <li key={i}>
                    <Link
                      className={`px-3  ${
                        pathname === link ? "text-[#0361CC] font-semibold" : ""
                      }`}
                      to={link}
                    >
                      {title}
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </Container>
      </nav>
    </>
  );
};

export default Navbar;

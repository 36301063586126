import React from "react";
import Services from "../../components/page-sections/10Ants/Services";
import { Container, Heading, ImageCarousel, Paragraph } from "../../components";
import { FAQ, WhySection } from "../../components/page-sections/common";
import WhatTheySay from "../../components/page-sections/common/WhatTheySay";
import { Header } from "../../components/layout";

const _10Ants = () => {
  return (
    <>
      <Header className="py-8">
        <Container variant="lg">
          <Heading variant="lg" className="mb-3 mt-40">
            10antsNG: Comprehensive Facility Management
          </Heading>
          <p className="max-w-[1091px]">
            10antsNG is the dedicated facility management arm of our real estate
            portfolio investment management firm. Combining advanced technology
            with hands-on expertise, 10antsNG offers a complete solution for
            maintaining and managing properties, ensuring that our clients'
            investments are not only preserved but optimized for long-term
            success.
          </p>
          <img
            className="mt-6 h-[480px] w-full object-cover rounded-3xl"
            src="/images/home-header-bg.jpg"
            alt="house"
          />
        </Container>
      </Header>
      <WhySection
        title="Why Choose 10antsNG?"
        paragraphs={[
          "10antsNG goes beyond traditional facility management by offering a hybrid model—combining on-site expertise with digital solutions. Our team of highly qualified facility managers, led by an experienced Chief Facility Officer, ensures that properties are kept in top condition. With our hands-on approach, we address the physical demands of property maintenance, while our mobile app streamlines communication and provides real-time insights for property owners.",
          "This dual approach allows us to deliver a service that is efficient, proactive, and reliable, ensuring that both tenants and property owners benefit from a well-managed, well-maintained environment.",
          "By choosing 10antsNG, you are investing in a management team that not only preserves your property but also enhances its value through professionalism, attention to detail, and a commitment to tenant satisfaction.",
        ]}
      />
      <Services />
      <section className="py-16 px-6 [&_.swiper-button-prev]:left-[32px]">
        <Heading className="text-center mb-2" variant="lg">
          Gallery
        </Heading>
        <Paragraph className="text-center max-w-[870px] mx-auto mb-10">
          At J.R. LTD our portfolio showcases a diverse range of high-value real
          estate investments, from luxury apartments to exclusive residential
          estates. Each project reflects our commitment to strategic investment,
          meticulous management, and long-term value creation. Explore our
          featured properties to see how we maximize returns for our clients
          through innovative real estate solutions.
        </Paragraph>
        <ImageCarousel />
      </section>
      <FAQ />
      <WhatTheySay />
    </>
  );
};

export default _10Ants;

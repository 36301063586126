import React from "react";
import Heading from "../../Heading";
import Container from "../../layout/Container";

interface CardProps {
  title: string;
  description: string;
  iconPath: string;
  textColor: string;
  bgColor: string;
}

const Card = ({
  title,
  description,
  iconPath,
  textColor,
  bgColor,
}: CardProps) => {
  return (
    <div
      className={`rounded-xl py-8 px-3`}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <img className="mb-12 w-[98px] h-[113px]" src={iconPath}  alt={title} />
      <p className="font-bold mb-2">{title}</p>
      <p className="text-xs">{description}</p>
    </div>
  );
};

const Services = () => {
  const serviceCards = [
    {
      title: "On-Site Facility Management",
      description:
        "Our experienced team of facility managers physically oversees the day-to-day operations and upkeep of properties. This includes regular property inspections, managing routine maintenance, coordinating repairs, and ensuring that all health, safety, and regulatory standards are met. ",
      iconPath: "/svgs/Portfolio.svg",
      textColor: "#475DFF",
      bgColor: "#E4E7FF",
    },
    {
      title: "Tenant Relations",
      description: "We prioritize maintaining excellent relationships with tenants through both personal interaction and our mobile platform. Our facility managers work closely with tenants to address concerns, resolve issues quickly, and maintain high levels of satisfaction. ",
      iconPath: "/svgs/House.svg",
      textColor: "#E0B567",
      bgColor: "#F9F1E2",
    },
    {
      title: "Preventive Maintenance",
      description:
        " To avoid costly repairs and maintain the long-term value of properties, we implement a proactive approach to maintenance. Our team conducts regular inspections and performs preventive repairs, ensuring that potential issues are identified and resolved before they become major problems.",
      iconPath: "/svgs/Arrow.svg",
      textColor: "#729B79",
      bgColor: "#F3F7F4",
    },
    {
      title: "Property Law Services.",
      description:
        "While our facility managers provide on-the-ground support, our facility management mobile application offers clients and tenants a convenient platform to stay connected.",
      iconPath: "/svgs/stock-pink.svg",
      textColor: "#E91FFF",
      bgColor: "#FFE3F8",
    },
    {
        title: "24/7 Maintenance Requests",
        description:
          "Tenants can submit maintenance requests through the app at any time, and our facility managers are notified immediately to address the issue..",
        iconPath: "/svgs/Stock.svg",
        textColor: "#1F8FFF",
        bgColor: "#E3F1FF",
      },
      {
        title: "Document Management",
        description:
          "Property owners can access important documents, maintenance reports, and tenant communications through the app, ensuring complete transparency and easy access to critical information.",
        iconPath: "/svgs/stock-red.svg",
        textColor: "#FF1F1F",
        bgColor: "#FFE3E3",
      },
      {
        title: "Real-Time Updates",
        description:
          " Both property owners and tenants receive real-time updates on the status of repairs, maintenance, and other services.",
        iconPath: "/svgs/stock-green.svg",
        textColor: "#0D684A",
        bgColor: "#E3FFEE",
      },
      {
        title: "Performance Monitoring",
        description:
          "Property owners can access important documents, maintenance reports, and tenant communications through the app, ensuring complete transparency and easy access to critical information.",
        iconPath: "/svgs/stock-purple.svg",
        textColor: "#7E1BFF",
        bgColor: "#F3EAFF",
      },
  ];
  return (
    <section className="px-6 py-16">
      <Container variant="lg">
        <Heading className="text-center" variant="lg">
          Our Services
        </Heading>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
          {serviceCards.map((card, i) => (
            <Card key={i} {...card} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Services;

import React from "react";
import { Button, Container, Heading, Input, Textarea } from "../../components";
import { Section } from "../../components/layout" ;

const ContactUs = () => {
  return (
    <Section className="pt-40 px-6" dataNavbarColor="dark">
      <Heading className="text-center mb-[60px]">Contact Us</Heading>
      <Container className="grid md:grid-cols-2">
        <div>
          {" "}
          <div className="mb-16">
            <p className="font-bold mb-2">Contact us</p>
            <p>
              Our team is here to help you<br />
              contact@jazzrealtiesltd.com
            </p>
          </div>
          <div>
            <p className="font-bold mb-2">Call us</p>
            <p>
              Mon - Fri from 10am to 6pm <br />
              +44 77 6996 6948 +234 807 143 9941
            </p>
          </div>
        </div>
        <form>
          <div className="grid gap-6">
            <Input label="Title" placeholder="Select an option" />
            <Input label="Full name" placeholder="Input full name" />
            <Input label="Email address" placeholder="Input email address" />
            <Input label="Phone number" placeholder="Input phone number" />
            <Textarea label="Message" placeholder="Tell us how we can help you..."/>
          </div>
          <Button className="block ml-auto mt-6">Submit</Button>
        </form>
      </Container>
    </Section>
  );
};

export default ContactUs;

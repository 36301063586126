import React from "react";
import { Button, Heading, Input, Select } from "../../components";
import { Section } from "../../components/layout";

const ContactAgent = () => {
  return (
    <Section className="pt-40">
      <Heading className="text-center mb-[60px]">Contact an Agent</Heading>
      <form className="grid max-w-[580px] mx-auto mb-8">
        <div className="grid gap-6">
          <Select label="Choose your city" placeholder="Select your city" required/>
          <Select label="Which of the units are you interested in?" placeholder="Select vehicle" required/>
          <Select label="When are you looking to book?" placeholder="Select an option" required />

          <h3 className="mt-16 font-semibold text-lg lg:text-2xl">Personal Details</h3>
          <Select label="Title" placeholder="Select an option" required/>
          <Input label="Full name" placeholder="Input full name" required />
          <Select label="Gender" placeholder="Select gender" required/>
          <Input label="Email address" type="email" placeholder="Input email address" required />
          <Input label="Preferred contact number" placeholder="Input phone number" required />
        </div>
        <div className="bg-[#AFAFAF0D] border border-[#EBEBEB] rounded-lg px-7 py-9 mt-14">
          <p>
            J.R. LTD will provide the information in this form to your nominated
            agent(s). The agent will handle your information in accordance with
            their privacy policy. Please note that agents and J.R. LTD are
            separate organisations, and that the agent may contact you using the
            details you have submitted, including through agent marketing
            communications.{" "}
          </p>
          <p className="mt-6">
            By clicking SUBMIT and providing your personal information via this
            web form, you consent to being contacted by J.R. LTD in accordance
            with our Privacy Policy available at http://www.jazzrealtiesltd.com/
            This includes using your personal information to send you marketing
            and information as outlined in our Privacy Policy.
          </p>
        </div>
        <Button className="block mx-auto mt-[224px]">Submit</Button>
      </form>
    </Section>
  );
};

export default ContactAgent;

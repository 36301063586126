import React, { ReactNode } from "react";

interface HeaderProps {
  children: ReactNode;
  className?: string;
  dataNavbarColor?: "light" | "dark";
  style?: any;
}

const Header = ({
  children,
  className,
  dataNavbarColor = "light",
  style
}: HeaderProps) => {
  return (
    <header
      className={`header px-6 ${className ? className : ""}`}
      data-navbar-color={dataNavbarColor}
      style={style}
    >
      {children}
    </header>
  );
};

export default Header;
